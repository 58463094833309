import { Col, Row } from "reactstrap";
import GraphImage from "../../../../assets/images/graph.svg";
// import backgroudDog from "../../../../assets/images/dog-background-image.png";
import "./styles.scss";

const Economy = () => {
  return (
    <div className="economyWrapper">
      {/* <img src={backgroudDog} className="backgroundDog" alt="backgroundDog" /> */}
      <div className="heading">
        <h1>$oDD Powers the oDDverse Economy</h1>
        {/* <p>Functions primarily as a utility token across all of oDDverse.</p> */}
      </div>
      <Row>
        <Col sm={12} md={6} className="card">
          <div className="content">
            <img src={GraphImage} alt="ODD Token" />
            <h4>1 $oDD = 0 FIAT</h4>
            <p>
              $oDD is oDDverse's utility token. They have no real-world
              financial value.
            </p>
          </div>
        </Col>
        <Col sm={12} md={6} className="pointsWrapper">
          <ol className="points">
            {[
              {
                title: "Yield",
                description: "Earn 10 $oDD or more tokens per day",
              },
              {
                title: "Spend",
                description:
                  "Buy ecosystem items - NFTs + Wearables - with $oDD",
              },
              {
                title: "Merge",
                description:
                  "Fuse bOOG + BOi to be part of oDDfluencers  with $oDD",
              },
              {
                title: "Govern",
                description: "Cast votes using $oDD in the future",
              },
            ].map((item, index) => (
              <li className="pointItem">
                <div className="number">
                  <span>0{index + 1}</span>
                </div>
                <div className="value">
                  <h6>{item.title}</h6>
                  <p>{item.description}</p>
                </div>
              </li>
            ))}
          </ol>
        </Col>
      </Row>
    </div>
  );
};

export default Economy;
