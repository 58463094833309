import React from "react";
import "./styles.scss";

const ExploreCard = ({
  title,
  phaseText,
  icon,
  active = false,
  gradientReverse = false,
}) => {
  return (
    <div
      className="exploreCard"
      data-gradient-reverse={gradientReverse}
      data-active={active}
    >
      <div className="content">
        <div className="imageContainer">
          <img className="universeImg" src={icon} alt="mint" />
        </div>
        <h5>{title}</h5>
        <p>{phaseText}</p>
      </div>
    </div>
  );
};

export default ExploreCard;
