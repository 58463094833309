import "./styles.scss";
import BoogEyesImage from "../../../../assets/images/boog-eyes.png";
import EarnODDImage from "../../../../assets/images/snooze-earn-odd.svg";
import GetFreeBoogImage from "../../../../assets/images/get-free-boog.svg";
import BoostYourCoinsImage from "../../../../assets/images/boost-your-coins.svg";
import Button from "../../../common/Button";
import { Link } from "react-router-dom";

const Snooze = () => {
  return (
    <div className="snoozeWrapper">
      <div className="heading">
        <img
          src={BoogEyesImage}
          alt="Rocket"
        />
        <h1>Snooze your oDD BOi</h1>
        <p>Snoozing is a Soft Staking mechanic</p>
      </div>
      <div className="cards">
        <div className="cardItem">
          <div>
            <img
              src={EarnODDImage}
              alt="EARN $oDD"
            />
            <h4>EARN $oDD</h4>
            <p>
              Stake your oDD BOis to earn 10 $oDD/day. Spend $oDD on other
              ecosystem NFTs and wearables.
            </p>
          </div>
        </div>
        <div
          className="cardItem"
          data-gradient-reverse={true}
        >
          <div>
            <img
              src={GetFreeBoogImage}
              alt="Get a FREE bOOG"
            />
            <h4>Get a FREE bOOG</h4>
            <p>Spend $oDD and claim a free bOOG on mint day (Q2, 2023).</p>
          </div>
        </div>
        <div className="cardItem">
          <div>
            <img
              src={BoostYourCoinsImage}
              alt="Boost Your Coins"
            />
            <h4>Boost Your Coins</h4>
            <p>
              Collect 500 $oDD and instantly double your rewards to get 20
              $oDD/day.
            </p>
          </div>
        </div>
      </div>

      <Button
        link="https://mint.oddverse.xyz"
        text="Snooze Your oDD BOi"
      />
    </div>
  );
};

export default Snooze;
