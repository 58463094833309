import React, { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  NavbarToggler,
  Collapse,
} from "reactstrap";
import Button from "../Button";
import Logo from "../../../assets/images/oddVerse-logo.svg";
import "./styles.scss";
import styles from "./styles.module.css";

const NavigationBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar
      color="dark"
      dark
      {...props}
      className="navigationBarWrapper"
    >
      <Container>
        <div className="d-flex justify-content-between align-items-center">
          <NavbarBrand href="/">
            <img
              src={Logo}
              alt="img"
              height="30px"
            />
          </NavbarBrand>
          <Nav
            navbar
            className="flex-row bold align-items-center gap-5"
          >
            <NavItem>
              <NavLink href="#odd-boi">oDD BOi</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#odd-boog">oDD bOOG</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#odd-fluencers">oDDFluencers</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#about-us">About Us</NavLink>
            </NavItem>

            <a
              href="https://mint.oddverse.xyz/"
              target="_blank"
              rel="noreferrer"
            >
              <Button text={"Snooze"} />
            </a>
          </Nav>

          <NavbarToggler onClick={toggle} />
        </div>

        <Collapse
          isOpen={isOpen}
          navbar
        >
          <NavItem>
            <NavLink href="#odd-boi">oDD BOi</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#odd-boog">oDD bOOG</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#odd-fluencers">oDD Fluencers</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#about-us">About Us</NavLink>
          </NavItem>

          <NavLink
            href="https://mint.oddverse.xyz/"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            <Button text={"Launch App"} />
          </NavLink>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
