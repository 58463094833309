import React from "react";
import Button from "../../../common/Button";
import "./styles.scss";
import { Col, Row } from "reactstrap";
import oDDBoogGif from "../../../../assets/images/boogs.gif";

const ODDBoog = () => {
  return (
    <div
      id="odd-boog"
      className="oddBoogWrapper"
    >
      <h3 className="heading">oDDverse Flagship Collection</h3>
      <Row>
        <Col
          md={12}
          lg={6}
        >
          <div className="contentWrapper">
            <h3 className="contentHeading">Meet oDD bOOG</h3>
            <p className="description">
              Well, it's hard to pin down exactly what a bOOG is. bOOGs are a
              part of us all. They exist somewhere between your suppressed alter
              ego and a brain fart. Remember that time when the waiter said”
              enjoy your meal” and you said “you too!”? Oof… or the time you
              hopped into random car thinking it was your Lyft. Why did you say
              that!? Why did you do that?!! Don't we know better? Of course, we
              do, it's not our fault, it was the bOOG. Embrace your inner bOOG.
            </p>
            <div>
              <Button text="Coming Soon" />
            </div>
          </div>
        </Col>
        <Col
          xs={4}
          className="gifContainer"
        >
          <img
            src={oDDBoogGif}
            alt="Boog Gif"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ODDBoog;
