import React from "react";
import Button from "../../../common/Button";
import oDDBOiGif from "../../../../assets/images/bois.gif";
import "./styles.scss";
import { Col, Row } from "reactstrap";

const ODDBoi = () => {
  return (
    <div
      id="odd-boi"
      className="oddBoiWrapper"
    >
      <h3 className="heading">oDDverse Genesis Collection</h3>
      <Row>
        <Col
          xs={4}
          className="gifContainer"
        >
          <img
            src={oDDBOiGif}
            alt="BOi Gif"
          />
        </Col>
        <Col
          md={12}
          lg={6}
        >
          <div className="contentWrapper">
            <h3 className="contentHeading">Meet oDD BOi</h3>
            <p className="description">
              Eyes look in different directions, been working on potty training
              for a few years now, tongue doesn't fit in his mouth, almost
              drowns when he takes a drink of water, in fact he can barely
              breath at all. He snores like a grizzly bear. He smells like old
              corn chips and halitosis, he barks at his own shadow and despite
              all that he's got a huge heart (which the vet says is
              problematic). He's a hairy tube of companionship, your ride or
              die, he's a good boi, he's an oDD Boi.
            </p>
            <div>
              <Button
                link="https://medium.com/@oddverse"
                text="Learn More"
              ></Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ODDBoi;
