import React from "react";
import rocketbOOG from "../../../../assets/images/rocketbOOG.png";

import exclusiveMembershipIcon from "../../../../assets/images/community-exclusive-membership.svg";
import oddCapitalIcon from "../../../../assets/images/community-odd-capital.svg";
import dailyOddIcon from "../../../../assets/images/community-daily-odd.svg";
import digitalFashionIcon from "../../../../assets/images/community-digital-fashion.svg";
import modelIcon from "../../../../assets/images/community-3d-model.svg";
import rareMerchIcon from "../../../../assets/images/community-rare-merch.svg";

import "./styles.scss";

const Card = ({ icon, title, description }) => {
  return (
    <div className="card">
      <img src={icon} alt={`${title} icon`} />
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  );
};

const Community = () => {
  return (
    <div className="communityWrapper">
      <div className="heading">
        <img src={rocketbOOG} alt="Rocket" />
        <h1>Community at the ❤️ of the Ecosystem</h1>
        <p>Core utilities for oDD BOi + oDD bOOG</p>
      </div>
      <div className="cards">
        <Card
          icon={exclusiveMembershipIcon}
          title="Exclusive Membership"
          description="Early community will enjoy ongoing benefits with exclusive access to all future collections."
        />
        <Card
          icon={oddCapitalIcon}
          title="oDD Capital"
          description="A % of secondary revenues will be used to fund and empower creators in the ecosystem."
        />
        <Card
          icon={dailyOddIcon}
          title="Daily $oDD"
          description="Holders can stake their oDD NFTs and earn $oDD tokens daily which unlocks FREE NFTs and wearables."
        />
        <Card
          icon={digitalFashionIcon}
          title="Digital Fashion"
          description="Make your character the most stylist in the metaverse -  2D traits of oDD NFTs will be crafted in 3D."
        />
        <Card
          icon={modelIcon}
          title="3D Model"
          description="Take your character anywhere - one of a kind fully animated AR models in USDZ and gLTF models for iOS and Android."
        />
        <Card
          icon={rareMerchIcon}
          title="Rare Merch"
          description="Look oDDer IRL with our high-quality limited-edition apparel for genesis holders."
        />
      </div>
    </div>
  );
};

export default Community;
