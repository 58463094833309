import BryanLashelleImage from "../../../../assets/images/bryan-lashelle.png";
import AatmaStudioImage from "../../../../assets/images/aatma-studio.png";
import BryanLashellePortfolioImage from "../../../../assets/images/bryan-lashelle-portfolio.png";
import AatmaStudioPortfolioImage from "../../../../assets/images/aatma-studio-portfolio.png";
import CallingCreatorsImage from "../../../../assets/images/calling-creators.png";
import NFTTeamImage from "../../../../assets/images/oDDverse-future.png";
import Button from "../../../common/Button";
import "./styles.scss";

const OurTeams = () => {
  return (
    <div className="ourTeamsWrapper">
      <div className="header">
        <h1>oDD Team</h1>
        <p>Artist led x Studio Backed</p>
      </div>

      <div className="studios">
        <div className="studio">
          <div className="info">
            <div>
              <h3>Bryan Lashelle</h3>
              <p>Visual Dev Artist + Art Director</p>
            </div>
            <img
              src={BryanLashelleImage}
              alt="Bryan Lashelle"
            />
          </div>
          <strong>20+ Years of Feature Animation</strong>
          <img
            src={BryanLashellePortfolioImage}
            alt="Bryan Lashelle Portfolio"
          />
          <Button
            link="https://www.imdb.com/name/nm1630231/"
            text="View Work"
          />
        </div>
        <div className="studio">
          <div className="info">
            <div>
              <h3>Aatma Studio</h3>
              <p>3D Animation House</p>
            </div>
            <img
              src={AatmaStudioImage}
              alt="Aatma Studio"
            />
          </div>
          <strong>Brands Ignited</strong>
          <img
            src={AatmaStudioPortfolioImage}
            alt="Aatma Studio Portfolio"
          />
          <Button
            link="https://www.aatmastudio.com"
            text="View Site"
          />
        </div>
      </div>
      <div className="callingCreators">
        <div className="info">
          <div>
            <h2>Calling Web 2 Creators</h2>
            <p>
              Let's create an oDD future together!
              <br />
              In-line with our credo of creator empowerment, we want to bring
              together (and generate employment) for a talented roster of
              creators from within our diverse community.
              <br />
              We are seeking an oDD creative bunch from our community: artists,
              3D animators, character specialists, game designers and more!
              <br />
              Let's build top shelf stuff - characters, worlds, metaverse
              experiences. You'll get to work alongside a rock-star creative
              team from the oDDverse!
              <br />
              An oDD goal is to build a creator DAO that operates as a fully
              global decentralized autonomous studio.
            </p>
          </div>
          <img
            src={CallingCreatorsImage}
            alt={"Calling Creators"}
          />
        </div>

        <h3>- Join Our Community -</h3>
        <img
          src={NFTTeamImage}
          alt={"Team"}
        />
      </div>
    </div>
  );
};

export default OurTeams;
