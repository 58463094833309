import React from "react";
import { Row } from "reactstrap";
import Button from "../../../common/Button";

import "./styles.scss";

import mintImage from "../../../../assets/images/mint-image-one.png";
import mintImagetwo from "../../../../assets/images/mint-image-two.png";

const Card = ({ canMint = false, date, title, preview }) => {
  if (canMint) {
    return (
      <div
        className="mintCard"
        data-active={true}
      >
        <div className="content">
          <img
            src={preview}
            alt="mint"
          />
          <Button
            link="https://opensea.io/collection/odd-boi-official"
            text="View on OpenSea"
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className="mintCard"
      data-active={false}
    >
      <div className="content">
        <img
          src={preview}
          alt="mint"
        />
        <h3>{title}</h3>
        <p>{date}</p>
      </div>
    </div>
  );
};

const Collections = () => {
  return (
    <Row className="collectionWrapper align-items-end">
      <Card
        title="oDD BOi"
        canMint={true}
        preview={mintImagetwo}
        date={"APRIL 2023"}
      />
      <Card
        title="oDD bOOG"
        canMint={false}
        preview={mintImage}
                date={
          <a href="https://opensea.io/collection/odd-boog/drop">Minting June 28</a>
        }
      />
    </Row>
  );
};

export default Collections;
