import React from "react";
import ExploreCard from "../../../common/ExploreCard";
import UniverseIcon from "../../../../assets/images/character-universe.svg";
import AnimatedStorytellingIcon from "../../../../assets/images/animated-storytelling.svg";
import ARExperiencesIcon from "../../../../assets/images/ar-experiences.svg";

import "./styles.scss";

const Ecosystem = () => {
  return (
    <div className="exploreEcosystem">
      <h1>Explore Our Web2 Ecosystem</h1>
      <div className="cards">
        <ExploreCard
          icon={UniverseIcon}
          active={true}
          title={"Character Universe"}
          phaseText={"Phase 1: Now + Ongoing"}
        />
        <ExploreCard
          icon={ARExperiencesIcon}
          title="AR Experiences"
          phaseText={"Phase 2: Soon"}
          gradientReverse={true}
        />
        <ExploreCard
          icon={AnimatedStorytellingIcon}
          title="Animated Storytelling"
          phaseText={"Phase 3: Future"}
        />
      </div>
    </div>
  );
};

export default Ecosystem;
