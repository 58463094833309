import React from "react";
import Button from "../../../common/Button";
import "./styles.scss";
import HopInImage from "../../../../assets/images/hop-in.svg";
import { Col, Row } from "reactstrap";

const HopIn = () => {
  return (
    <div
      id="about-us"
      className="hopInWrapper"
    >
      <div className="header">
        <h1>About</h1>
      </div>

      <Row>
        <Col
          md={12}
          lg={6}
        >
          <div className="contentWrapper">
            <p className="description">
              oDDverse is an ecosystem. A statement of intent. Your adventure.
              Thoughtfully crafted digital assets and magical worlds are being
              built - Character IPs, AR experiences, and fun animated
              storytelling - with a single $oDD token to power the entire oDD
              economy. The possibilities are endless in this new reality. We
              look forward to drive the future of entertainment on Web3 and
              shape the Metaverse as we go!
              <br />
              Our purpose is to make space for creativity to flourish in Web3.
              We 🧡 creators! We'll empower diverse artists globally by creating
              possibilities and build out the vision. Join us on an
              unconventional journey to build an oDD future together.
            </p>
            {/* <div>
              <Button text="Learn More"></Button>
            </div> */}
          </div>
        </Col>
        <Col>
          <img
            src={HopInImage}
            alt="oddBoi"
          />
        </Col>
      </Row>
    </div>
  );
};

export default HopIn;
