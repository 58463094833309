import React from "react";
import "./styles.scss";

const Button = ({ text, link, ...props }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="link"
    >
      <button
        className="btn"
        {...props}
      >
        {text}
      </button>
    </a>
  );
};

export default Button;
