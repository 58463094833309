import "./styles.scss";
import YellowGridIcon from "../../../../assets/images/yellow-grid.svg";
import ODDFluencersImage from "../../../../assets/images/odd-fluencers.png";

const ODDFluencers = () => {
  return (
    <div
      id="odd-fluencers"
      className="oddFluencersWrapper"
    >
      <img
        src={YellowGridIcon}
        alt="Grid decoration"
        className="gridTopRight"
      />
      <div className="content">
        <img
          src={ODDFluencersImage}
          alt="oDDFluencers"
        />
        <div className="contentDescription">
          <div className="contentWrapper">
            <p className="comingSoon">COMING SOON</p>
            <h3 className="contentHeading">oDDFluencers</h3>
            <p className="description">
              A small supply, breeding mechanic driven fusion, closed discord
              projects in the works.
            </p>
            <p className="highlighedText">(Post BOi And bOOG Mint)</p>
          </div>
        </div>
      </div>
      <img
        src={YellowGridIcon}
        alt="Grid decoration"
        className="gridBottomLeft"
      />
    </div>
  );
};

export default ODDFluencers;
