import React from "react";
import { Container } from "reactstrap";
import "./styles.scss";

import { Footer } from "../../components/common";
import {
  Collections,
  ODDFuture,
  Ecosystem,
  ODDBoi,
  ODDBoog,
  ODDFluencers,
  Community,
  Economy,
  Snooze,
  HopIn,
  OurTeams,
  FAQ,
} from "../../components/pages/home";
import backgroudDog from "../../assets/images/dog-background-image.png";

const Home = () => {
  return (
    <>
      <Container className="sections">
        <Collections />
        <ODDFuture />
        <Ecosystem />
        <ODDBoi />
        <ODDBoog />
        <ODDFluencers />
        <Community />
      </Container>
      <div style={{ position: "relative", margin: "150px 0" }}>
        <img src={backgroudDog} className="backgroundDog" alt="backgroundDog" />
        <Container>
          <Economy />
        </Container>
      </div>
      <Container className="sections">
        <Snooze />
        <HopIn />
        <OurTeams />
        <FAQ />
      </Container>
      <Footer />
    </>
  );
};

export default Home;
