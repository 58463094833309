import "./styles.scss";
import DiscordIcon from "../../../assets/images/discord.svg";
import TwitterIcon from "../../../assets/images/twitter.svg";
import OpenSea from "../../../assets/images/opensea.svg";

const Footer = () => {
  return (
    <div className="footerWrapper">
      <div className="socials">
        <a
          href="https://discord.gg/oddverse"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="Discord"
            src={DiscordIcon}
          />
        </a>
        <a
          href="https://twitter.com/oddboi_nft"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="Twitter"
            src={TwitterIcon}
          />
        </a>

        <a
          href="https://opensea.io/collection/odd-boi-official"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="OpenSea"
            src={OpenSea}
          />
        </a>
      </div>
      <div className="links">
        <a
          href="https://www.oddcompany.xyz/"
          target="_blank"
          rel="noreferrer"
        >
          oDD COMPANY
        </a>
        <a
          href="https://www.notion.so/The-Hitchhiker-s-Guide-to-the-oDDverse-76e17a0b717043e998132940421fa122"
          target="_blank"
          rel="noreferrer"
        >
          oDDpaper
        </a>
        <a
          href="https://docs.google.com/document/d/1HUnadZSNAVGMnfw75JhZUjOP3O22idq0e6z-aslkBk4/edit"
          target="_blank"
          rel="noreferrer"
        >
          Terms
        </a>
      </div>
      <p>
        All rights reserved © Bryan Lashelle x Aatma Studio LLC = oDD COMPANY{" "}
        {new Date().getFullYear()}
      </p>
      <p>
        For inquiries contact{" "}
        <a href="mailto:hi@oddverse.xyz">hi@oddverse.xyz</a>
      </p>
    </div>
  );
};

export default Footer;
