import React from "react";
import oddVerse from "../../../../assets/images/oDDverse-future.png";
import "./styles.scss";

const ODDFuture = () => {
  return (
    <div className="oddFuture">
      <h1>The Future is oDD</h1>
      <div className="futureoDDverseImage">
        <img src={oddVerse} alt="mint" />
      </div>
    </div>
  );
};

export default ODDFuture;
