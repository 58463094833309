import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";
import "./styles.scss";

const faqContent = [
  {
    question: "What is oDDverse?",
    answer:
      "oDDverse is an ecosystem - Character universe, Animated Storytelling and AR experiences.  We are building a quirky character universe built for Web3. The goal is to build a library of character I.P's that lends itself to animated storytelling and fun AR experiences in the metaverse. oDD BOi is the Genesis collection; oDD bOOG is the flagship collection. They are both a character IP driven generative art + utility focused NFT project.",
  },
  {
    question: "What benefits do oDD BOi genesis holders receive?",
    answer:
      "Priority with future projects. Guaranteed Allowlist spots. Genesis Role on Discord. Event Invites. Access to AR experiences and Animated Storytelling (later stage). Limited-edition Genesis merchandise. bOOST $oDD earnings.",
  },
  {
    question: "What is Snoozing?",
    answer:
      "Snoozing is a soft-staking mechanic. When you stake your NFT, it remains in your wallet and you get to earn 10 $oDD or more daily. Spend it to claim other ecosystem items!",
  },
  {
    question: "What utilities come with the flagship oDD bOOG collection?",
    answer:
      "The flagship collection will have its unique set of utilities. To be announced closer towards bOOG mint.",
  },
  {
    question: "How many traits + What about rarities?",
    answer:
      "BOis and bOOGs come with over 275+ hand crafted visual characteristics with some super rare attributes. Rarities are distributed super well. There will be something for everyone.",
  },
  {
    question: "What can i do with $oDD?",
    answer:
      "$oDD will be the means of exchange in the oDDverse economy. You can stake to earn $oDD everyday. You can spend it on ecosystem items like NFTs and 3D Wearables later on.",
  },
  {
    question: "What would you do for the creative economy?",
    answer:
      "Our credo is creator empowerment. We'd love to bring together a talented constellation of creative community from across the globe. We will plough back a % of secondary revenues to fund artist projects. Let's create a phenomenal future together!",
  },
  {
    question: "Info on smart contract token standard?",
    answer:
      "oDD NFTs are stored as ERC-721A tokens on the Ethereum Blockchain and hosted on IPFS. Contract address is published on Medium.",
  },
];

const FAQ = () => {
  return (
    <div className="faqWrapper">
      <h1>FAQs</h1>
      <UncontrolledAccordion
        defaultOpen={["1"]}
        stayOpen
      >
        {faqContent.map((item, index) => (
          <AccordionItem key={index}>
            <AccordionHeader targetId={`${index + 1}`}>
              {item.question}
            </AccordionHeader>
            <AccordionBody accordionId={`${index + 1}`}>
              {item.answer}
            </AccordionBody>
          </AccordionItem>
        ))}
      </UncontrolledAccordion>
    </div>
  );
};

export default FAQ;
